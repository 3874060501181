function search() {
  const element = document.getElementById('searchResults');
  if (element) {
    element.addEventListener("keyup", (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      if (document.querySelector('#searchResults').value === '') {
        const originalUrl = window.location.origin + window.location.pathname;
        let queryString = removeFromQueryString('q');
        window.location.href = originalUrl + queryString;
      } else {
        document.querySelector('.header-searchresults__form-search-btn').click();
      }
    }
    });
  }
}
function filterOnSearchTerm() {
  const searchBtn = document.querySelector('.header-searchresults__form-search-btn');

  if (searchBtn) {
    searchBtn.addEventListener('click', () => {
      const searchTerm = 'q=' + document.querySelector('#searchResults').value;
      const originalUrl = window.location.origin + window.location.pathname;
      let queryString = '?' + searchTerm;

      window.location.href = originalUrl + queryString;
    });
  }
}

search();
filterOnSearchTerm();