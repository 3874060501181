import {
  tns
} from '../../../../node_modules/tiny-slider/src/tiny-slider';

function bannerLogoSlider () {
  const slider = document.getElementById('js-banner-logos');

  if (slider) {
    const options = {
      container: '#js-banner-logos',
      mode: 'carousel',
      gutter: 24,
      items: 2,
      responsive: {
        576: {
          items: 3
        },
        992: {
          items: 4
        },
        1200: {
          items: 5
        },
        1440: {
          items: 6
        },
        1920: {
          items: 7
        }
      },
      controlsPosition: 'bottom',
      nav: false
    };

    return tns(options);
  }
}

bannerLogoSlider();
