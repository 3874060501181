window.addEventListener('keydown', (e) => {
  if (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13) {
    if (e.target.nodeName == 'INPUT' && e.target.type == 'text') {
      e.preventDefault();
      return false;
    }
  }
}, true);

function toggleDropdownFilters() {
  const dropdownBtns = document.querySelectorAll('.js-filters-dropdown button');

  if (dropdownBtns) {
    for (let i = 0; i < dropdownBtns.length; i++) {
      const buttons = dropdownBtns[i];

      buttons.addEventListener('click', (e) => {
        const target = e.target,
          dropdown = target.nextElementSibling;

        if (dropdown.classList.contains('is-block')) {
          dropdown.classList.remove('fade-in');
          target.setAttribute('aria-expanded', 'false');
          setTimeout(() => {
            dropdown.classList.remove('is-block');
          }, 251);
        } else {
          closeAllDropdownFilters();
          dropdown.classList.add('is-block');
          target.setAttribute('aria-expanded', 'true');
          setTimeout(() => {
            dropdown.classList.add('fade-in');
          }, 1);
        }
      });
    }
  }
}

function closeDropdownFiltersOnChange() {
  const checkboxes = document.querySelectorAll('.js-filters-dropdown input[type=checkbox]');

  if (checkboxes) {
    for (i = 0; i < checkboxes.length; i++) {
      const allBoxes = checkboxes[i];

      allBoxes.addEventListener('change', (e) => {
        const target = e.target;

        if (target.checked) {
          setTimeout(() => {
            closeAllDropdownFilters();
          }, 150);
        }
      });
    }
  }
}

function closeAllDropdownFilters() {
  const dropdownBtns = document.querySelectorAll('.js-filters-dropdown button');

  if (dropdownBtns) {
    for (let i = 0; i < dropdownBtns.length; i++) {
      const target = dropdownBtns[i],
        dropdown = target.nextElementSibling;

      dropdown.classList.remove('fade-in');
      target.setAttribute('aria-expanded', 'false');
      dropdown.classList.remove('is-block');
    }
  }
}

toggleDropdownFilters();
closeDropdownFiltersOnChange();

function keyupSearchTerm(id) {
  const element = document.getElementById(id);
  if (element) {
    element.addEventListener("keyup", (event) => {
      event.preventDefault();
      if (event.keyCode === 13) {
        if (document.querySelector('#' + id).value === '') {
          const originalUrl = window.location.origin + window.location.pathname;
          let queryString = removeFromQueryString('qa');
          window.location.href = originalUrl + queryString;
        } else {
          document.querySelector('.filters__form-search-btn').click();
        }
      }
    });
  }
}

function filterOnSearchTerm() {
  const searchBtn = document.querySelector('.filters__form-search-btn');

  if (searchBtn) {
    searchBtn.addEventListener('click', () => {
      const searchValue = document.querySelector('#searchAgenda') !== null ? document.querySelector('#searchAgenda').value : document.querySelector('#searchNews').value;
      const searchTerm = 'qa=' + searchValue;
      const originalUrl = window.location.origin + window.location.pathname;
      let queryString = removeFromQueryString('qa');
      // console.log(searchTerm);
      // console.log(queryString);

      if (queryString !== '') {
        queryString += '&' + searchTerm;
      } else {
        queryString = '?' + searchTerm;
      }
      
      window.location.href = originalUrl + queryString;

    });
  }
}

function filterOnDate() {
  const dateCheckboxes = document.querySelectorAll('#js-dates-list input');
  if (dateCheckboxes) {
    for (let i = 0; i < dateCheckboxes.length; i++) {
      const checkbox = dateCheckboxes[i];

      checkbox.addEventListener('change', (e) => filterDate(e, checkbox));
    }
  }
}

function filterOnMarketSegment() {
  const typeCheckboxes = document.querySelectorAll('#js-marketsegments-list input');
  if (typeCheckboxes) {
    for (let i = 0; i < typeCheckboxes.length; i++) {
      const checkbox = typeCheckboxes[i];

      checkbox.addEventListener('change', (e) => filterType(e, checkbox, 'data-ms', 'ms'));
    }
  }
}

function filterOnBusinessActivity() {
  const typeCheckboxes = document.querySelectorAll('#js-businessactivities-list input');
  if (typeCheckboxes) {
    for (let i = 0; i < typeCheckboxes.length; i++) {
      const checkbox = typeCheckboxes[i];

      checkbox.addEventListener('change', (e) => filterType(e, checkbox, 'data-ba', 'ba'));
    }
  }
}

function filterOnType() {
  const typeCheckboxes = document.querySelectorAll('#js-types-list input');
  if (typeCheckboxes) {
    for (let i = 0; i < typeCheckboxes.length; i++) {
      const checkbox = typeCheckboxes[i];

      checkbox.addEventListener('change', (e) => filterType(e, checkbox, 'data-type', 'type'));
    }
  }
}

function removeFilter() {
  const selectedFilters = document.querySelectorAll('.filters__form-tag');
  if (selectedFilters) {
    for (let i = 0; i < selectedFilters.length; i++) {
      const filter = selectedFilters[i];

      filter.addEventListener('click', (e) => {
        const dataAttribute = document.querySelectorAll("[data-" + filter.getAttribute('data-type') + "='" + filter.getAttribute('data-filter') + "']");

        if (filter.getAttribute('data-type') === "type") {
          dataAttribute[0].checked = false;
          filterType(null, dataAttribute[0], 'data-type', 'type');
        } else if (filter.getAttribute('data-type') === "date") {
          dataAttribute[0].checked = false;
          filterDate(null, dataAttribute[0]);
        } else {
          const originalUrl = window.location.origin + window.location.pathname;
          let queryString = removeFromQueryString('q');
          queryString = removeFromQueryString('page');
          window.location.href = originalUrl + queryString;
        }
      });
    }
  }
}

function removeFromQueryString(key) {
  let queryString = window.location.search;

  if (queryString.indexOf(key) > -1) {
    let currentQueryString = queryString.substring(queryString.indexOf(key) - 1);
  
    currentQueryString = currentQueryString.split('&')[0] === '' ? currentQueryString.split('&')[1] : currentQueryString.split('&')[0];
    queryString = queryString.replace(currentQueryString, '');
  
    if (queryString.startsWith('&')) {
      queryString = '?' + queryString.substring(1);
    }
  
    if (queryString.endsWith('&')) {
      queryString = queryString.substring(0, queryString.length - 1);
    }
  }

  return queryString;
}

function filterDate(e, checkbox) {
  if (e) {
    e.stopPropagation();
  }
  const selectedDate = "date=" + checkbox.getAttribute('data-date');
  const originalUrl = window.location.origin + window.location.pathname;
  let queryString = window.location.search;
  queryString = removeFromQueryString('page');

  if (queryString.indexOf('date') > -1) {
    // remove the current filter for date
    queryString = removeFromQueryString('date');
  } else {
    if (queryString !== '') {
      queryString += '&' + selectedDate;
    } else {
      queryString = '?' + selectedDate;
    }
  }

  window.location.href = originalUrl + queryString;
}

function filterType(e, checkbox, attribute, type) {
  if (e) {
    e.stopPropagation();
  }
  const selectedType = checkbox.getAttribute(attribute).replaceAll(' ', '%20');
  const originalUrl = window.location.origin + window.location.pathname;
  let queryString = window.location.search;
  queryString = removeFromQueryString('page');
  let currentType = queryString.indexOf(type + '=') > -1 ? queryString.substring(queryString.indexOf(type + '=')) : '';
  if (currentType !== '') {
    currentType = currentType.split('&')[0] === '' ? currentType.split('&')[1] : currentType.split('&')[0];
  }

  const tempCurrentType = currentType;

  if (checkbox.checked === true) {
    if (currentType !== '') {
      currentType += ',' + selectedType;
    } else {
      currentType = type + '=' + selectedType;
    }
  } else {
    currentType = currentType.split('=')[1].replace(selectedType, '');
    if (currentType.split('=') !== '') {
      if (currentType.split('=')[0].startsWith(',')) {
        currentType = currentType.substring(1);
      }

      if (currentType.split('=')[0].endsWith(',')) {
        currentType = currentType.substring(0, currentType.length - 1);
      }
    }
    if (currentType != '') {
      currentType = type + '=' + currentType;
    }
  }

  if (queryString !== '') {
    if (queryString.indexOf(type) > -1) {
      queryString = queryString.replace(tempCurrentType, currentType)
    } else {
      queryString += '&' + currentType;
    }
  } else {
    queryString = '?' + currentType;
  }
  queryString = queryString.replace('?&', '?');

  if (queryString.endsWith('&')) {
    queryString = queryString.substring(0, queryString.length - 1);
  }

  if(queryString === '?') {
    queryString = '';
  }

  window.location.href = originalUrl + queryString;
}

keyupSearchTerm('searchAgenda');
keyupSearchTerm('searchNews');
filterOnSearchTerm();
filterOnDate();
filterOnType();
filterOnBusinessActivity();
filterOnMarketSegment();
removeFilter();
