const accordionHeaders = document.querySelectorAll('[data-accordion-header]');

function accordionCollapse () {
  if (accordionHeaders) {
    for (let i = 0; i < accordionHeaders.length; i++) {
      accordionHeaders[i].addEventListener('click', () => {
        let parent = accordionHeaders[i].parentElement;
        let panel = parent.parentElement;
        let target = parent.nextElementSibling;
        let expanded = accordionHeaders[i].getAttribute('aria-expanded') === 'true' || false;
        accordionHeaders[i].setAttribute('aria-expanded', !expanded);
        if (!expanded) {
          target.hidden = expanded;
          setTimeout(() => {
            panel.classList.add('is-active');
          }, 1);
        } else {
          panel.classList.remove('is-active');
          setTimeout(() => {
            target.hidden = expanded;
          }, 401);
        }
      });
    }
  }
}

accordionCollapse();
