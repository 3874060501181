function showShareButtons() {
  const shareButton = document.getElementById('js-share-btn'),
        links = document.getElementById('js-share-links');

  if (shareButton !== null) {
    shareButton.addEventListener('click', () => {
      shareButton.classList.add('is-hidden');
      links.classList.remove('is-hidden');
    });
  }
}

function hideShareButtons() {
  const closeButton = document.getElementById('js-share-close'),
        shareButton = document.getElementById('js-share-btn'),
        links = document.getElementById('js-share-links');

  if (closeButton !== null) {
    closeButton.addEventListener('click', () => {
      links.classList.add('is-hidden');
      shareButton.classList.remove('is-hidden');
    });
  }
}

showShareButtons();
hideShareButtons();
